@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import "./animations";

$color-black: #23292d;
$color-green: #54b69e;
$color-white: #fff;

$color-header-home: #c988de;
$color-header-parent: #2de7c3;
$color-header-teen: #7cdd8c;
$color-header-dw: #0ab2fa;
$color-header-faq: #28aaf4;
$gradient-home: linear-gradient(180deg, #d47cdd 12.95%, #86cbe1 103.06%);
$gradient-parent: linear-gradient(180deg, #2de7c3 12.95%, #0abae7 103.06%);
$gradient-teen: linear-gradient(180deg, #66d39f 6.37%, #22b7db 64.85%);
$gradient-dw: linear-gradient(180deg, #0ca7ff 0%, #00ebdd 104.88%);
$gradient-faq: linear-gradient(180deg, #00cbf8 0%, #7b68ee 103.74%);

$shadow-default: 0px 6px 14px -10px rgba($color-black, 0.7);
$shadow-default-bottom: 0px -6px 14px -10px rgba($color-black, 0.7);
$font-default: "Poppins", sans-serif;

$height-header: 120px;
$width-content: 1366px;
$radius-default: 8px;

$notification-top: 0;
$notification-height: 100px;

$category-size: 120px;

body {
  font-family: $font-default;
  margin: 0;
  font-size: 14px;
  color: $color-black;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1.2;
}

input,
select,
button,
.button {
  border-radius: 50px;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  font-family: $font-default;
  outline: none;
  text-decoration: none;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

button,
.button {
  background: $color-green;
  color: $color-white;
  box-shadow: $shadow-default;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  &.button-very-big {
    padding: 0.7rem 2rem;
    font-size: 1rem;
    display: inline-flex;
    width: fit-content;
    margin-top: 1rem;
    border: solid thin darken($color-green, 2);
    box-shadow: $shadow-default, 0 0.5rem 3rem 0 rgba($color-black, 0.3);

    &:hover {
      background: lighten($color-green, 3);
    }
  }
}

* {
  box-sizing: border-box;
}

.content {
  width: 100%;
  max-width: $width-content;
  margin: 0 auto;
  position: sticky;
}

.header {
  backdrop-filter: blur(10px);
  background: linear-gradient(
    180deg,
    $color-header-home 100%,
    rgba($color-header-home, 0) 100%
  );
  z-index: 9999;
  top: 0;
  position: sticky;
}

.header .content {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  height: $height-header;

  .logo {
    margin-right: 1rem;

    img {
      height: 4rem;
    }
  }

  a:not(.logo-link) {
    color: $color-white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    background: rgba($color-black, 0);
    transition: padding 0.3s, background 0.2s;

    &:hover,
    &.active {
      padding: 0.3rem 1rem;
      background: $color-black;
    }
  }

  .links-left {
    flex: 1;

    a {
      color: $color-white;
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      padding: 0.3rem 0.5rem;
      border-radius: 1rem;
      background: rgba($color-black, 0);
      transition: padding 0.3s, background 0.2s;

      &:hover,
      &.active {
        padding: 0.3rem 1rem;
        background: $color-black;
      }
    }
  }
}

.hero {
  position: relative;

  .hero-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.hero .content {
  display: flex;
  padding: 1rem 2rem;
  margin-top: -$height-header;
  padding-top: calc(#{$height-header} + 4rem);
  z-index: 0;

  .hero-image {
    width: 40vw;
    max-width: 500px;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: #{$height-header / 1.5};
  }

  h1 {
    font-size: 84px;
    line-height: 0.9;
  }

  h3 {
    font-size: 50px;
    margin-bottom: 2rem;
  }

  form {
    margin-top: 2rem;
  }
}

.documentations {
  padding: 1rem 2rem;
  margin-top: -$height-header;
  padding-top: calc(#{$height-header} + 4rem);
  z-index: 0;

  h5 {
    font-size: 25px;
    line-height: 0.9;
    margin-bottom: 1rem;
  }

  ol {
    margin: 0;
    font-size: 20px;
    margin-bottom: 1rem;
    padding-left: 1rem;

    &.second-level {
      margin-top: 10px;
      padding-left: 3rem;
      li {
        margin-bottom: 0.5rem;
      }
    }

    &.character-bullets {
      list-style-type: lower-alpha;
    }

    &.roman-bullets {
      list-style-type: lower-roman;
    }

    li {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
    font-size: 20px;
    margin-bottom: 1rem;
  }
}
.section {
  position: relative;

  &:nth-of-type(2n + 1) {
    .artwork {
      order: 2;
    }
    .bg-art {
      position: absolute;
      top: -40%;
      right: 0;
      left: unset;
      height: 180%;
    }
  }

  p {
    margin-bottom: 0;
  }

  .bg-art {
    position: absolute;
    top: -20%;
    left: 0;
    right: unset;
    height: 100%;
    z-index: -1;
  }

  .bg-art--1 {
    top: 20% !important;
  }

  .bg-art--3 {
    top: 10% !important;
  }
}
.section .content {
  display: flex;
  padding: 2rem 2rem;

  h2 {
    font-size: 72px;
  }

  .artwork {
    width: 50%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 500px;

      &.scaled-up {
        transform: scale(1.2);
      }

      &.scaled-down {
        transform: scale(0.8);
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;

    .icon-guide {
      display: flex;
      align-items: center;
      text-align: left;

      img {
        height: 40px;
        width: 40px;
        margin-bottom: -15px;
        margin-right: 20px;
      }
    }
  }
}

.footer {
  padding: 2rem;
  position: relative;

  .footer-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: -2;
  }

  .footer-lower {
    display: flex;
    align-items: flex-start;
    margin: 5rem 0;
    margin-top: 7rem;

    p {
      margin: 0;
      color: $color-white;
    }

    .left {
      padding-right: 2rem;
      width: 100%;
      max-width: 400px;

      .logo {
        margin-bottom: 1rem;
      }

      .links {
        a {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          background: rgba($color-black, 0.3);
          border-radius: 48px;
          margin-right: 0.5rem;

          &:hover {
            background: rgba($color-black, 0.5);
          }
        }
      }
    }

    a,
    a:link {
      color: $color-white !important;
      margin-right: 1rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer-content-card-shadow {
  position: absolute;
  z-index: -1;
  width: 100%;
  max-width: $width-content;
  height: 80%;
  left: 50%;
  top: 100px;
  transform: translateX(-50%) scale(1.1, 1);
  filter: blur(80px);
  opacity: 0.5;
}

.footer-content-card {
  position: relative;
  background: $color-white;
  border-radius: $radius-default;
  padding: 2rem 5rem;
  border: solid thin rgba($color-black, 0.1);
  overflow: hidden;

  .footer-card-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 450px;
    z-index: 0;
  }

  .logo-section {
    z-index: 1;
    position: relative;
    margin: 3rem 0;
    .description {
      font-size: 24px;
      text-align: center;
    }

    .logos {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 60px;
        margin: 2rem 3rem;

        &.visa {
          height: 40px;
        }

        &.xfers {
          height: 80px;
        }
      }
    }
  }

  .try-for-free {
    top: -50px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 4rem 0 4rem;
    z-index: 2;

    h2 {
      font-size: 48px;
    }

    .steps {
      display: flex;

      .step {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 2rem;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $gradient-home;
          width: 120px;
          height: 120px;
          border-radius: 120px;
          margin: 1rem auto;

          img {
            width: 60px;
          }
        }

        h3 {
          font-size: 14px;
        }
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 350px;
      margin-right: 4rem;
    }
  }
}

.teen {
  .header {
    background: linear-gradient(
      180deg,
      $color-header-teen 100%,
      rgba($color-header-teen, 0) 100%
    );
    position: sticky;
  }

  .try-for-free .steps .icon {
    background: $gradient-teen !important;
  }

  .section {
    text-align: center;
  }

  .features {
    display: flex;
    text-align: center;
    margin: 7rem 0;

    h2 {
      font-size: 24px;
    }

    .feature {
      padding: 0 1rem;
      flex: 1;

      .icon {
        img {
          width: 80px;
        }
      }
    }
  }
}

.dw {
  .header {
    background: linear-gradient(
      180deg,
      $color-header-dw 100%,
      rgba($color-header-dw, 0) 100%
    );
    position: sticky;
  }

  .try-for-free .steps .icon {
    background: $gradient-dw !important;
  }
}

.parent {
  .header {
    background: linear-gradient(
      180deg,
      $color-header-parent 100%,
      rgba($color-header-parent, 0) 100%
    );
    position: sticky;
  }

  .try-for-free .steps .icon {
    background: $gradient-parent !important;
  }
}

.faq {
  .header {
    background: linear-gradient(
      180deg,
      $color-header-faq 100%,
      rgba($color-header-faq, 0) 100%
    );
    position: sticky;
  }

  .try-for-free .steps .icon {
    background: $gradient-faq !important;
  }
}

.tutorials {
  .header {
    background: linear-gradient(
      180deg,
      $color-header-faq 100%,
      rgba($color-header-faq, 0) 100%
    );
    position: sticky;
  }

  .try-for-free .steps .icon {
    background: $gradient-faq !important;
  }
}

.video-content {
  width: 100%;
  height: 75vh;
  overflow: hidden;
  border-radius: $radius-default;
}

.notifications {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5rem;
  position: absolute;
  transform: translate3d(0, -#{$notification-height/2}, 0);

  .notification {
    background: $color-white;
    border: solid thin rgba($color-black, 0.1);
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    border-radius: $radius-default;
    display: flex;
    align-items: center;
    box-shadow: 0px 15px 24px rgba($color-black, 0.07);
    transition: transform 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96),
      opacity 0.3s;
    position: absolute;
    left: 2rem;

    .description {
      flex: 1;
    }

    .t1 {
      font-weight: bold;
    }

    .t2 {
      display: flex;
      font-size: 14px;
      color: rgba($color-black, 0.5);

      &::before {
        content: url("../img/icon-clock.svg");
        padding-right: 0.3em;
      }
    }

    &.notification-0 {
      opacity: 0;
      z-index: 0;
      transform: scale(1)
        translateY(#{$notification-top - $notification-height * 2});
    }

    &.notification-1 {
      opacity: 1;
      z-index: 3;
      transform: scale(1)
        translateY(#{$notification-top - $notification-height * 1});
    }
    &.notification-2 {
      opacity: 1;
      z-index: 2;
      transform: scale(0.98)
        translateY(#{$notification-top - $notification-height * 0});
    }
    &.notification-3 {
      opacity: 1;
      z-index: 1;
      transform: scale(0.96)
        translateY(#{$notification-top + $notification-height * 1});
    }
    &.notification-4 {
      opacity: 0;
      z-index: 0;
      transform: scale(0.94)
        translateY(#{$notification-top + $notification-height * 2});
    }
  }
}

.hero-texts {
  padding: 5rem 0;
  position: relative;
  font-size: 2rem;

  .hero-text {
    pointer-events: none;
    width: 100%;
    transition: transform 0.3s ease-in-out, opacity 0.3s;
  }

  .hero-text-0 {
    position: absolute;
    opacity: 0;
    top: 0;
    transform: translateY(-100%);
  }
  .hero-text-1 {
    position: relative;
    opacity: 1;
    top: 0;
  }
  .hero-text-2 {
    position: absolute;
    opacity: 0;
    top: 0;
    transform: translateY(100%);
  }
}

.dw {
  .mobile-menu {
    background: $color-header-dw;
  }
}

.mobile-menu {
  background: $color-header-parent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 19999;
  color: $color-black;

  a {
    color: $color-black !important;
    margin-bottom: 0.3rem;
    padding: 1rem 2rem !important;
    border-radius: 3rem !important;

    &.active,
    &:hover {
      padding: 1rem 4rem !important;
      color: $color-white !important;
    }
  }
}

#menu-hamburger,
#menu-back {
  cursor: pointer;
}

#menu-hamburger,
.mobile-menu {
  display: none;
}

.faq {
  .sidebar {
    width: 380px;
    flex-shrink: 0;

    .arrow {
      display: none;
    }

    h3 {
      display: block;
      width: 100%;
    }
  }
  .search-form {
    display: flex;
    flex-wrap: wrap;

    input {
      border: solid thin rgba($color-black, 0.3);
      margin-right: 0.3rem;
      flex: 3;
    }

    button {
      margin-right: 0;
      flex: 1;
    }
  }
}

.faq-content {
  position: relative;

  h3 {
    margin: 1rem 0;
  }

  .filter-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      margin-right: 0.2rem;
      flex: 1;

      &:nth-of-type(1) {
        background-color: transparent;
        color: $color-header-teen;
        border: solid thin $color-header-teen;
      }
      &:nth-of-type(2) {
        background-color: transparent;
        color: $color-header-parent;
        border: solid thin $color-header-parent;
      }
      &:nth-of-type(3) {
        background-color: transparent;
        color: $color-header-dw;
        border: solid thin $color-header-dw;
        flex-basis: 180px;
      }

      &.active,
      &:hover {
        &:nth-of-type(1) {
          background-color: $color-header-teen;
          color: $color-white;
        }
        &:nth-of-type(2) {
          background-color: $color-header-parent;
          color: $color-white;
        }
        &:nth-of-type(3) {
          background-color: $color-header-dw;
          color: $color-white;
        }
      }
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .category {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      width: $category-size;
      margin: 0.2rem;
      border: solid 3px rgba($color-black, 0.1);
      padding: 1rem;
      border-radius: $radius-default;
      cursor: pointer;

      &:hover,
      &.active {
        border-color: rgba($color-black, 0.7);
        box-shadow: $shadow-default;
      }

      .icon {
        flex-shrink: 0;
        width: #{$category-size / 1.5};
        height: #{$category-size / 1.5};
        background: center no-repeat;
        background-size: contain;
        margin-bottom: 0.3rem;
        opacity: 0.7;
      }

      span {
        text-align: center;
        line-height: 1.2;
      }
    }
  }

  .questions {
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: solid thin rgba($color-black, 0.1);

    .highlighted {
      background: rgba($color-header-faq, 0.5);
      font-weight: bold;
      display: inline-block;
      padding: 0 0.3rem;
      border-radius: 1rem;
    }

    .no-results {
      color: rgba($color-black, 0.3);
      font-size: 1.5rem;

      a {
        display: block;
        font-size: 14px;
        color: $color-green;
        padding: 1rem 0;
        font-weight: bold;
        text-decoration: none;
      }
    }

    .filters-wrapper {
      margin-bottom: 1rem;

      h3 {
        margin-bottom: 0.3rem;
      }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;

      .filter {
        background: rgba($color-black, 0.8);
        color: $color-white;
        padding: 0.3rem 0.7rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        border-radius: 2rem;
        cursor: pointer;
        font-weight: bold;
        flex-shrink: 0;

        &:hover {
          background: rgba($color-black, 0.8);
          color: rgba($color-white, 0.7);

          &::after {
            opacity: 1;
          }
        }

        &::after {
          content: "+";
          font-size: 1.2rem;
          display: inline-flex;
          line-height: 0;
          transform: rotate(45deg);
          opacity: 0.8;
          padding-left: 0.3rem;
        }

        &.filter--user-0 {
          background: $color-header-teen;
          color: $color-white;
        }

        &.filter--user-1 {
          background: $color-header-parent;
          color: $color-white;
        }

        &.filter--user-2 {
          background: $color-header-dw;
          color: $color-white;
        }
      }
    }
    .faq-item {
      margin-bottom: 3rem;
    }
  }
}

.tutorials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  $icon-rotation: 55deg;

  .stats {
    text-align: left;
    width: 100%;
    font-weight: nor;
  }

  .tutorial {
    display: flex;
    flex-grow: 1;
    flex-basis: 300px;
    height: 200px;
    align-items: center;
    padding: 1rem;
    margin: 0.3rem;
    border: 0.742188px solid #e6e6e6;
    border-radius: $radius-default;
    cursor: pointer;
    overflow: hidden;

    &.watched {
      box-shadow: $shadow-default;

      .icon {
        filter: grayscale(1);
        transform: scale(2.1) rotate($icon-rotation);
      }
    }

    &:hover {
      box-shadow: $shadow-default;

      .icon {
        filter: grayscale(0);
        transform: scale(2.1) rotate($icon-rotation);
      }
    }

    .icon {
      box-shadow: $shadow-default;
      border-radius: $radius-default;
      padding: 1rem;
      margin-right: 1rem;
      transform: scale(2) rotate($icon-rotation) translate3d(0, 0, 0);
      z-index: -1;
      transition: all 0.2s ease-out;
      filter: grayscale(0);

      &::before {
        content: "";
        display: block;
        width: 48px;
        height: 48px;
        background: url("../img/logo-transparent-small.webp") no-repeat center;
        background-size: contain;
        transform: rotate(-$icon-rotation);
      }
    }

    .description {
      text-align: left;
      line-height: 1.2;

      .how-to {
        font-size: 0.8em;
      }
      .title {
        font-weight: bold;
      }

      .watched {
        font-size: 12px;
        opacity: 0.8;
        margin-top: 1rem;
        color: $color-green;
        font-weight: bold;
      }
    }
  }
}

.fullscreen {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color-white, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 4rem;
  z-index: 29999;
  backdrop-filter: blur(10px);
  pointer-events: none;
  opacity: 0;
  transform: scale(0.95);
  transition: transform 0.3s, opacity 0.3s;

  &.active {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
  }

  .button-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    img {
      width: 2rem;
      height: 2rem;
      padding: 0.4em;
      border-radius: 2rem;
      background: rgba($color-black, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  iframe {
    width: 80vw;
    height: 45vw;
    border-radius: $radius-default;
    box-shadow: $shadow-default;
  }
}

@media screen and (max-width: 1200px) {
  .try-for-free {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 !important;

    .left {
      max-width: unset !important;
      margin-right: 0 !important;
      align-items: center !important;
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 1260px) {
  .header {
    padding-bottom: 1rem;
    .links-right {
      display: none;
    }

    .links-left {
      position: absolute;
      top: 90px;
      left: 1rem;
    }

    .logo {
      img {
        max-width: 80%;
      }
    }

    #menu-hamburger {
      display: block;
      position: absolute;
      top: 48px;
      right: 2rem;
      height: 21px;
    }

    .mobile-menu {
      &.active {
        display: flex;
      }

      #menu-back {
        display: block;
        position: fixed;
        top: 48px;
        right: 2rem;
        height: 21px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .links-left {
    margin-top: 1rem;
    display: block;
    width: calc(100% - 2rem);

    a {
      display: block;
      width: 100%;
    }
  }

  .header .content {
    margin-bottom: 2rem;
  }

  .hero .content .hero-content {
    padding-top: 80px;
  }
}

@media screen and (max-width: 1000px) {
  h2 {
    font-size: 8vw !important;
  }

  .section .content {
    flex-direction: column;
    text-align: center;
    align-items: center;

    .artwork {
      width: 100%;
      order: -1;

      .scaled-up {
        transform: unset !important;
      }

      .with-bottom {
        margin-bottom: 2rem;
      }
    }

    .notifications {
      height: $notification-height * 4;
      position: relative;
      transform: translate3d(0, $notification-height, 0);
      padding: 0 !important;

      .notification {
        width: 100% !important;
        left: 0;
        text-align: left;
      }
    }

    .features {
      flex-direction: column;
      margin: 3rem 0 !important;

      .feature {
        margin-bottom: 3rem;
      }
    }

    .left {
      max-width: unset !important;
      margin-right: 0 !important;
      align-items: center !important;
      margin-bottom: 3rem;
    }
  }

  .footer-content-card {
    padding: 2rem !important;

    .steps {
      flex-direction: column;

      .step {
        padding: 0 !important;
      }
    }
  }

  .logo-section {
    .logos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        margin: 1rem !important;

        &.visa {
          max-width: 150px;
        }
      }
    }
  }

  .footer-lower {
    flex-direction: column;
    align-items: center !important;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: unset !important;
      padding: 0 !important;
      margin: 0;
      margin-bottom: 2rem;
    }

    .right {
      text-align: center;

      a {
        display: block;
        margin-right: 0;
        margin-top: 1rem;
      }
    }
  }

  .faq {
    .hero .content p {
      padding-bottom: 4rem;
    }

    .sidebar {
      width: unset;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .filters,
      .filter-buttons,
      .categories {
        justify-content: flex-start;
        text-align: left;
      }

      .filters {
        margin-right: 1rem;
      }

      .categories {
        margin-right: 0;
        .category {
          margin: 0.3rem;
          width: $category-size * 1.5;
          flex-direction: row;

          .icon {
            width: $category-size / 3;
            height: $category-size / 3;
            margin: 0;
            margin-right: 1rem;
          }

          span {
            text-align: left;
          }
        }
      }
    }

    .questions {
      text-align: left;
      margin-left: 0;
      padding-left: 0;
      border: none;
    }
  }
}

@media screen and (max-width: 960px) {
  .app-store-icons {
    justify-content: center;
  }

  .hero {
    .content {
      flex-direction: column;
      text-align: center;

      h1 {
        font-size: 10vw !important;
      }

      p {
        font-size: 16px;
      }

      form {
        display: flex;
        flex-direction: column;
      }

      .hero-image {
        width: 100%;
      }

      .hero-content {
        align-items: center;
      }
    }
  }

  .footer {
    padding: 1rem !important;

    .content {
      padding: 0 !important;
    }
  }

  .content {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .notification {
    padding: 1rem !important;
    font-size: 10px !important;

    .icon {
      display: none;
    }

    .t1,
    .d1 {
      font-size: 16px;
    }
  }

  .faq .sidebar {
    .filters {
      width: 100%;
      margin-right: 0;
    }

    .categories,
    .filter-buttons {
      width: 100%;
      .arrow {
        display: inline-block;
        margin-left: 0.5rem;
        line-height: 0;
        font-size: 1.2rem;
        transform: rotate(180deg);
      }

      &.hidden {
        .arrow {
          transform: rotate(0deg);
        }
        .category,
        button {
          display: none;
        }
      }
    }
    // display: none;
  }
}

@media screen and (max-width: 345px) {
  .header {
    .links-left a {
      font-size: 4vw !important;
      padding: 1vw 2vw !important;
    }
  }
}

.signup-form {
  input,
  button {
    font-size: 1.1rem;
  }
}

.try-for-free-floating {
  width: 100%;
  font-size: 1rem;
  background: $color-white;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  align-items: center;
  text-align: center;
  box-shadow: $shadow-default;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  p {
    margin: 0;
    font-size: 10pt;
    margin-bottom: 0.5rem;
  }

  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem !important;
  }

  a.button {
    margin: 0;
  }

  @media screen and (min-width: 640px) {
    max-width: 480px;
    border-radius: $radius-default;
    bottom: 1rem;
    right: 1rem;
    border: solid thin rgba($color-black, 0.1);
  }
}

.icon-arrow-indicator {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
  transition: all 0.3s;
  background: $color-white;
  pointer-events: none;
  width: 31px;

  &.visible {
    opacity: 1;
  }

  img {
    position: absolute;
  }
}

.artwork picture img {
  width: 100%;
  max-width: 500px;

  &.with-bottom {
    margin-bottom: 2rem;
  }

  &.scaled-up {
    transform: scale(1.2);
  }

  &.scaled-down {
    transform: scale(0.8);
  }
}

.app-store-icons {
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;

  img {
    max-width: 200px;
    margin-right: 0.5rem;
  }
}

table {
  border: 1px solid #dee2e6;
  margin-bottom: 10px;

  tbody {
    td {
      padding: 2px;
      border-top: 1px solid #dee2e6;
    }
    tr {
      td:nth-child(1) {
        border-right: 1px solid #dee2e6;
      }
      td {
        padding: 8px;
      }
    }
  }

  .table-active {
    background-color: #dee2e6;
    td:nth-child(1) {
      padding: 4px 8px;
    }
    td:nth-child(2) {
      padding: 0;
    }
  }
}

.privacy-policy {
  h5 {
    margin-top: 30px;
  }
  .red-notice {
    color: red;
  }
}
