/* ----------------------------------------------
 * Generated by Animista on 2020-9-4 15:36:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes wobble-move-slow {
  0%,
  100% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0.5rem);
  }
}

@keyframes wobble-move-slow-scaled-down {
  0%,
  100% {
    transform: translateY(-0.5rem) scale(0.7);
  }
  50% {
    transform: translateY(0.5rem) scale(0.7);
  }
}

@keyframes wobble-move-slow-scaled-up {
  0%,
  100% {
    transform: translateY(-0.5rem) scale(1.2);
  }
  50% {
    transform: translateY(0.5rem) scale(1.2);
  }
}

@keyframes wobble-move-slow-1 {
  0%,
  100% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0.1rem);
  }
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: fade-in-bottom 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.text-pop-up-top {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animated] {
  -webkit-animation: wobble-move-slow 5s cubic-bezier(0.54, -0.02, 0.43, 0.99)
    infinite;
  animation: wobble-move-slow 5s cubic-bezier(0.54, -0.02, 0.43, 0.99) infinite;

  &.scaled-down {
    -webkit-animation: wobble-move-slow-scaled-down 5s
      cubic-bezier(0.54, -0.02, 0.43, 0.99) infinite;
    animation: wobble-move-slow-scaled-down 5s
      cubic-bezier(0.54, -0.02, 0.43, 0.99) infinite;
  }

  &.scaled-up {
    -webkit-animation: wobble-move-slow-scaled-up 5s
      cubic-bezier(0.54, -0.02, 0.43, 0.99) infinite;
    animation: wobble-move-slow-scaled-up 5s
      cubic-bezier(0.54, -0.02, 0.43, 0.99) infinite;
  }
}

.wobble-move-slow-1 {
  -webkit-animation: wobble-move-slow-1 1s cubic-bezier(0.54, -0.02, 0.43, 0.99)
    infinite;
  animation: wobble-move-slow-1 1s cubic-bezier(0.54, -0.02, 0.43, 0.99)
    infinite;
}

@keyframes arrow-indicator {
  20%,
  80% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.icon-arrow-indicator {
  img:nth-child(1) {
    animation: arrow-indicator 1s linear 0s infinite;
  }
  img:nth-child(2) {
    animation: arrow-indicator 1s linear 0.33s infinite;
  }
  img:nth-child(3) {
    animation: arrow-indicator 1s linear 0.66s infinite;
  }
}
